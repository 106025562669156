import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";

const Payment = () => {
  const [trialPrice, setTrialPrice] = useState(null);
  const [trialPeriod, setTrialPeriod] = useState(null);
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const params = useLocation();
  const searchParams = new URLSearchParams(params.search);
  const status = searchParams.get("status");
  const merchant_oid = searchParams.get("merchant_oid");
  const total_amount = searchParams.get('total_amount');
  const paytr_token = searchParams.get('paytr_token');
 
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
  });

  const checkPayment = async () => {
    if (status === "success") {
      iziToast.success({
        message: "Payment Successful",
        position: "topCenter",
      });
      try {
        const { data } = await axiosClient.post(
          "/payment/callback",
          // { status, merchant_oid },
          { status, merchant_oid, total_amount, paytr_token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        navigate("/location-detail");
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    checkPayment();
  }, []);

  const fetchPricing = async () => {
    try {
      const { data } = await axiosClient.get("/prices-details");
      setTrialPrice(data.price);
      setTrialPeriod(data.time_period);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      const { data } = await axiosClient.post("/intial-payment", userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      if (data.result.status === "success") {
        window.location.href = `https://www.paytr.com/odeme/guvenli/${data.result.token}`;
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>Add Payment Info</h1>
              {/* <h6>Welcome Back</h6> */}
              <div className="login-form row">
                <div className="col-lg-6">
                  <div className="login-item pay-item">
                    <label htmlFor="">Name</label>
                    <input
                      required
                      type="text"
                      name="name"
                      onChange={handleChange}
                      id=""
                      placeholder="Full Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      required
                      onChange={handleChange}
                      name="email"
                      id=""
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">Phone Number</label>
                    <input
                      type="number"
                      required
                      onChange={handleChange}
                      name="phone"
                      id=""
                      placeholder="0000 000 000"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="address"
                      id=""
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="loc-text">
                    <span>Your Plan</span>
                    <span>{trialPeriod ? trialPeriod : "Loading..."}</span>
                  </div>
                  <div className="loc-text">
                    <span>Price</span>
                    <span>₹ {trialPrice ? trialPrice : "Loading..."}</span>
                  </div>
                </div>
                <div className="login-btn">
                  <button onClick={() => handleSubmit()}>
                    Continue Payment
                  </button>
                </div>

             
              </div>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Payment;
