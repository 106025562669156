import React, { useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosClient from "../axios-client";
import iziToast from 'izitoast';
import axios from 'axios';
import { useStateContext } from "../contexts/AuthContextProvider";

const Access = () => {
  const navigate = useNavigate();
  const { apipath } = useStateContext();
  const params = useLocation();
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);
  const searchParams = new URLSearchParams(params.search);
  const phone = searchParams.get('phone');
  const email = searchParams.get('email');
  const id = searchParams.get('id');

  const denyLocation = async () => {
    try {
      const userData = {
        location_id : id,
        status : "false"
      }
      console.log(userData);
        const { data } = await axiosClient.post("/save-location", userData);
        console.log(data);
        if(data.status !== "false"){
          // iziToast.success({
          //   message: data.message,
          //   position: "topCenter"
          // });
        }
        else{
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
    } catch (err) {
      console.error(err);
    }
    iziToast.error({
      message: "Location Access Denied.",
      position: "topCenter"
    });
    
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      console.log("Requesting location access...");
      setStatus('Requesting location access...');
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          try {
            const userData = {
              location_id : id,
              latitude : position.coords.latitude,
              longitude : position.coords.longitude,
              status : "true"
            }
            console.log(userData);
              const { data } = await axiosClient.post("/save-location", userData);
              console.log(data);
              if(data.status !== "false"){
                iziToast.success({
                  message: data.message,
                  position: "topCenter"
                });
              }
              else{
                iziToast.error({
                  message: data.message,
                  position: "topCenter"
                });
              }
          } catch (err) {
            console.error(err);
          }
          
          console.log("location access granted", location);
          setStatus('Location access granted');
          setError(null);
        },
        async (err) => {
          iziToast.error({
            message: "Location access denied",
            position: "topCenter"
          });
          
          try {
            const userData = {
              location_id : id,
              status : "false"
            }
            console.log(userData);
              const { data } = await axiosClient.post("/save-location", userData);
              console.log(data);
              if(data.status !== "false"){
                // iziToast.success({
                //   message: data.message,
                //   position: "topCenter"
                // });
              }
              else{
                iziToast.error({
                  message: data.message,
                  position: "topCenter"
                });
              }
          } catch (err) {
            console.error(err);
          }
          setError(err.message);
          if (err.code === err.PERMISSION_DENIED) {
            console.log('Location access denied');
            iziToast.error({
              message: "Location access denied",
              position: "topCenter"
            });
            setStatus('Location access denied');
          } else {
            console.log('Error occurred while fetching location');
            setStatus('Error occurred while fetching location');
          }
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
      setStatus('Geolocation not supported');
    }
  };
  return (
    <div className="overlay">
      <div className="request-access-box">
        <FaLock className="access-icon" />
        <h2>Grant Access</h2>
        <p>Do you allow us to access your location?</p>
        <div className='prompt-btn'>
          <button onClick={denyLocation} className="deny-button">Deny</button>
          <button onClick={getLocation} className="access-button">Allow</button>
        </div>
      </div>
    </div>
  );
};

export default Access;
