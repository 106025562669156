import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";

const Plan = () => {
  const location = useLocation();
  const { phone, code } = location.state || {};

  const { setToken, setUser } = useStateContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [trialPrice, setTrialPrice] = useState(null);
  const navigate = useNavigate();

  const fetchPricing = async () => {
    try {
      const { data } = await axiosClient.get("/subscription/prices");
      setTrialPrice(data.trial_price);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError("This field should not be blank.");
      return;
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      const { data } = await axiosClient.post("/signup", { email });
      setUser(data.user);
      setToken(data.token);
      if (data.status === "false") {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      } else {
        iziToast.success({
          message: "Registered Successfully and your email password sent to your mail",
          position: "topCenter",
        });
        navigate("/payment");
      }
    } catch (err) {
      const response = err.response;
      if (response && response.status === 422) {
        if (response.data.errors && response.data.errors.email) {
          setError(response.data.errors.email[0]);
        } else {
          setError("Error signing up, please try again.");
        }
      } else {
        setError("Error signing up, please try again.");
      }
      console.error(err);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container location-1">
          <div className="col-lg-10 mx-auto location-2">
            <div className="plan-header">
              <h5>Location Found ! ! !</h5>
              <h2>
                We have located the device with number <br />
                <span>
                  {code}-{phone}
                </span>
              </h2>
            </div>
            <div className="row plan-row">
              <div className="col-lg-6">
                <div className="plan-left">
                  <h5>Our Trial Pack :</h5>
                  <div>
                    <li>
                      Get a full report on {code}-{phone}
                    </li>
                    <li>Look up numbers & usernames</li>
                    <li>Send anonymous voice messages</li>
                    <li>Get detailed insights about any phone number</li>
                  </div>
                  <div className="px-4 mt-4">
                    <div className="loc-text">
                      <span>Price</span>
                      <span>₹ {trialPrice ? trialPrice : "Loading..."}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="plan-right">
                  <h5>Sign up to get precise location :</h5>
                  <form onSubmit={handleSubmit} className="login-form">
                    <div className="login-item">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {error && <p style={{ color: "#ff8780" }}>{error}</p>}

                    <div className="login-btn">
                      <button type="submit">Continue</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Plan;
