import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axiosClient from "../axios-client";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      formErrors.email = "Invalid email address";
    }

    if (!formData.phone.trim()) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      formErrors.phone = "Phone number must be 10-15 digits";
    }

    if (!formData.subject.trim()) {
      formErrors.subject = "Subject is required";
    }

    if (!formData.message.trim()) {
      formErrors.message = "Message is required";
    }

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      axiosClient
        .post("/contact", formData)
        .then((response) => {
          setSuccessMessage("Message sent successfully");
          setErrorMessage("");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          setErrorMessage("Error sending message");
          setSuccessMessage("");
        });
    }
  };

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login contact">
          <div>
            <div className="login-left">
              <h1 className="text-center">Contact Us</h1>
              <h6 className="text-center">We’ll get back to you</h6>
              <form onSubmit={handleSubmit} className="row login-form mt-4">
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter Name"
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter Email"
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="phone">Phone no</label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter Phone no."
                    />
                    {errors.phone && (
                      <p className="text-danger">{errors.phone}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Enter Subject"
                    />
                    {errors.subject && (
                      <p className="text-danger">{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="login-item">
                    <label htmlFor="message">Message</label>
                    <input
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Write your message"
                    />
                    {errors.message && (
                      <p className="text-danger">{errors.message}</p>
                    )}
                  </div>
                </div>

                <div className="login-check">
                  <input type="file" name="file" id="file" />
                </div>
                <div className="contact-btn">
                  <button type="submit">Send</button>
                </div>
              </form>

              {successMessage && (
                <p className="text-success mt-3">{successMessage}</p>
              )}

              {errorMessage && (
                <p className="text-danger mt-3">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
