import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";

const Forgot = () => {
  const navigate = useNavigate()
  const {  apipath } = useStateContext();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const reset = "reset";

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    try {
      const response = await axiosClient.post("/forgot-password", {
        email: email,
      });

      if (response.data.status === "true") {
        setMessage("Please check your email for the reset link.");
        iziToast.success({
          message: "Please check your email for the reset link.",
          position: "topCenter"
        });
        navigate("/otp", { state: { email, reset } });
      } else {
        iziToast.error({
          message: "Something went wrong. Please try again.",
          position: "topCenter"
        });
        setError("Something went wrong. Please try again.");
      }
    } catch (err) {
      setError("Error: " + err.response?.data?.message || err.message);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="Help" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="Logo" />
              </div>
              <h1>Forgot Password ?</h1>
              <h6>No issue, we can help you</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
              
                <div className="login-btn">
                  <button type="submit">Continue</button>
                </div>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                <p className="register">
                  Remembered Password?{" "}
                  <Link to="/login" className="text-decoration-none">
                    <span>Login</span>
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="Phone" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forgot;
