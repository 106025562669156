import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";

const Profile = () => {
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileTab, setProfileTab] = useState("account");
  const [edit, setEdit] = useState(false);
  const [phoneHistory, setPhoneHistory] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [paymentNotFound, setPaymentNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSubscription = async () => {
    try {
      const { data } = await axiosClient.get("/packages-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.subscription_type) {
        const today = new Date();
        const expiryDate = new Date(data.expiry_date);

        if (expiryDate < today) {
          setSubscriptionExpired(true);
          setSubscription(null);
        } else {
          setSubscription({
            type: data.subscription_type,
            expiryDate: data.expiry_date,
            joined: data.joined,
          });
          setSubscriptionExpired(false);
        }
      } else {
        setPaymentNotFound(true);
      }
    } catch (err) {
      console.error("Error fetching subscription details:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
    plan: "",
  });
  const [userprofile, setUserProfile] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
  });

  const handleChange = (e) => {
    setUserProfile({ ...userprofile, [e.target.name]: e.target.value });
  };

  const handleProfileTab = (tab) => {
    setProfileTab(profileTab === tab ? null : tab);
  };

  const getProfile = async () => {
    try {
      const { data } = await axiosClient.get("/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setProfile(data);
      setUserProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  const userData = async () => {
    try {
      const { data } = await axiosClient.get("/fetch-phone-history", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPhoneHistory(data.data.history);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userData();
    getProfile();
  }, []);

  const handleSubmit = async (e) => {
    try {
      const { data } = await axiosClient.post("/update-profile", userprofile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      if (data.success) {
        iziToast.success({
          message: data.success,
          position: "topCenter",
        });
        getProfile();
        setEdit(!edit);
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    console.log(phoneHistory);
  }, [phoneHistory]);

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container location-1">
          <div className="col-lg-10 mx-auto location-2 p-5">
            <div className="plan-header">
              <h2>My Profile</h2>
            </div>
            <div className="profile-tabs">
              <button
                className={`profile-links ${
                  profileTab === "account" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("account")}
              >
                Account
              </button>
              <button
                className={`profile-links ${
                  profileTab === "subscription" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("subscription")}
              >
                Subscription
              </button>
              <button
                className={`profile-links ${
                  profileTab === "history" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("history")}
              >
                History
              </button>
            </div>

            {profileTab === "account" && (
              <div>
                <div className="profile-top">
                  <h5>Personal Information</h5>
                  <span onClick={() => setEdit(!edit)}>Edit</span>
                </div>
                {edit ? (
                  <div className="row login-form mt-4">
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          name="name"
                          id=""
                          onChange={handleChange}
                          value={userprofile.name}
                          placeholder="Enter first Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">Phone No.</label>
                        <input
                          type="text"
                          name="phone"
                          id=""
                          onChange={handleChange}
                          value={userprofile.phone}
                          placeholder="Enter phone no"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          name="email"
                          id=""
                          onChange={handleChange}
                          value={userprofile.email}
                          placeholder="Enter email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">Address</label>
                        <input
                          type="text"
                          name="address"
                          id=""
                          onChange={handleChange}
                          value={userprofile.address}
                          placeholder="Enter Address"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">City</label>
                        <input
                          type="text"
                          name="city"
                          id=""
                          onChange={handleChange}
                          value={userprofile.city}
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">State</label>
                        <input
                          type="text"
                          name="state"
                          id=""
                          onChange={handleChange}
                          value={userprofile.state}
                          placeholder="Enter State"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">Country</label>
                        <input
                          type="text"
                          name="country"
                          id=""
                          onChange={handleChange}
                          value={userprofile.country}
                          placeholder="Enter Country"
                        />
                      </div>
                    </div>
                    <div className="contact-btn">
                      <button onClick={() => handleSubmit()}>Update</button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Name</h6>
                        <p>{profile.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Phone Number</h6>
                        <p>{profile.phone}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Email</h6>
                        <p>{profile.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Address</h6>
                        <p>{profile.address}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>City</h6>
                        <p>{profile.city}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>State</h6>
                        <p>{profile.state}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Country</h6>
                        <p>{profile.country}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Plan</h6>
                        <p>{profile.plan}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>Joined</h6>
                        <p>{profile.created_at}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* {profileTab === "subscription" && (
              <div>
                <div className="profile-top">
                  <h5>Subscriptions</h5>
                </div>
   
            

                {subscriptionExpired ? (
            <div className="profile-top">
              <h5>Your Plan is expired...</h5>
              <Link to="/payment" className="text-decoration-none text-white">
                <span>Pay</span>
              </Link>
            </div>
          ) : paymentNotFound ? (
            <div className="profile-top">
              <h5>Your Plan is not active...</h5>
              <Link to="/payment" className="text-decoration-none text-white">
                <span>Pay</span>
              </Link>
            </div>
          ) : (
            <div>
              <div className="profile-top">
                <h5>Subscriptions</h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="how-card">
                    <h6>Subscription Type: {subscription.type}</h6>
                    <p>Valid till: {subscription.expiryDate}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
              </div>
            )} */}
            {profileTab === "subscription" && (
              <div>
                <div className="profile-top">
                  <h5>Subscriptions</h5>
                </div>
                {loading ? (
                  <div className="loading">Loading...</div>
                ) : subscriptionExpired ? (
                  <div className="profile-top">
                    <h5>Your Plan is expired...</h5>
                    <Link
                      to="/payment"
                      className="text-decoration-none text-white"
                    >
                      <span>Pay</span>
                    </Link>
                  </div>
                ) : paymentNotFound ? (
                  <div className="profile-top">
                    <h5>Your Plan is not active...</h5>
                    <Link
                      to="/payment"
                      className="text-decoration-none text-white"
                    >
                      <span>Pay</span>
                    </Link>
                  </div>
                ) : subscription && subscription.type ? (
                  <div>
                    <div className="profile-top">
                      <h5>Subscriptions</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="how-card">
                          <h6>Subscription Type: {subscription.type}</h6>
                          <p>Valid till: {subscription.expiryDate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            {profileTab === "history" && (
              <div>
                <div className="profile-top">
                  <h5>History</h5>
                </div>
                <div className="row">
                  {phoneHistory.map((phistory) => {
                    const phone = phistory.phone_number;
                    const code = phistory.country_code || "+91";
                    const formattedDate = new Date(
                      phistory.created_at
                    ).toLocaleString("en-US", {
                      year: "numeric", // Year (e.g., '2024')
                      month: "long", // Month name (e.g., 'August')
                      day: "numeric", // Day of the month (e.g., '29')
                      hour: "2-digit", // Hour (e.g., '09')
                      minute: "2-digit", // Minute (e.g., '05')
                      second: "2-digit", // Second (e.g., '30')
                    });
                    return (
                      <div className="col-lg-6">
                        <div
                          className="history-card"
                          onClick={() =>
                            navigate("/location-detail", {
                              state: { phone, code },
                            })
                          }
                        >
                          <div>
                            <h6>
                              {phistory.country_code}-{phistory.phone_number}
                            </h6>
                            <p>Located on {formattedDate}</p>
                          </div>
                          <i className="bi bi-info-circle"></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
