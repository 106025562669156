import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import axios from "axios";

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  
    const userId = localStorage.getItem('userId');

  const { setToken, setUser, apipath } = useStateContext();
  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
     
      const { data } = await axiosClient.post("/login", { email, password });
      setUser( data.user);
  
      setToken(data.token);
      if(data.status === "false"){
        iziToast.error({
          message: data.message,
          position: "topCenter"
        });
      }
      else{
        iziToast.success({
          message: "Login Successful",
          position: "topCenter"
        });

        navigate("/location-detail");


      }
     
    } catch (err) {
      iziToast.error({
        message: "Invalid Email or password",
        position: "topCenter"
      });
      setError("Invalid Email or password");
      console.error(err);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>Login</h1>
              <h6>Welcome Back</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="">Email</label>
                  <input
                    type="email"
                    id=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Email"
                  />
                </div>
                <div className="login-item">
                  <label htmlFor="">Password</label>
                  <div className="login-group">
                    <input
                      type={isVisible ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Password"
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                  <Link
                    to="/forgot"
                    className="text-decoration-none text-white"
                  >
                    <p>Forgot Password?</p>
                  </Link>
                </div>
                {/* <div className="login-check">
                  <input type="checkbox" name="" id="" />{" "}
                  <label htmlFor="">Remember Me</label>
                </div> */}
                {error && <p style={{ color: "#ff8780" }}>{error}</p>}
                <div className="login-btn">
                  <button type="submit">Login</button>
                </div>
                <p className="register">
                  Don't have an Account ?{" "}
                  <Link to="/register" className="text-decoration-none">
                    <span>Register</span>{" "}
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Login;
