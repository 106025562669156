import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    // Function to set the google translate cookie
    const setCookie = (name, value, days) => {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };


    const setGoogleTranslateLanguage = () => {
      setCookie('googtrans', '/en/tr', 365);
    };

    if (!window.googleTranslateScriptAdded) {
      const addScript = document.createElement('script');
      addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      addScript.async = true;
      document.body.appendChild(addScript);
      window.googleTranslateScriptAdded = true;
    }

    window.googleTranslateElementInit = () => {
      if (!window.googleTranslateInitialized) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            includedLanguages: 'en,tr', 
          },
          'google_translate_element'
        );
        window.googleTranslateInitialized = true;

        // Set Turkish as the default language
        setGoogleTranslateLanguage();
      }
    };
  }, []);

  return (
    <div id="google_translate_element" style={{ 
      position: 'fixed !important',
      top: '10px !important',
      right: '10px !important',
      zIndex: '9999 !important',
      overflow: 'auto' 
    }}></div>
  );
  
};

export default GoogleTranslate;
