import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";

const Location = () => {
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { code, phone, details } = location.state || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);
  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
    full_address: "",
    longitude: "",
    latitude: "",
    timezone: "",
  });

  useEffect(() => {
    if (details) {
      setUserLocation({
        country: details.country || "Unknown",
        city: details.city || "Unknown",
        timezone: details.timezone || "Unknown",
      });
      const timer = setTimeout(() => {
        // navigate("/plan", { state: { phone, code } });
        if (user) {
          navigate("/location-detail");
        } else {
          navigate("/plan", { state: { phone, code } });
        }
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      navigate("/");
    }
  }, [details]);

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container">
          <div className="col-lg-8 mx-auto location-1">
            <div className="col-lg-10 mx-auto location-2">
              <div className="col-lg-10 mx-auto location-3">
                <div className="loc-icon">
                  <img src="images-local/location-1.png" alt="" />
                </div>
                <h6>
                  {" "}
                  <div className="spinner"></div>Finding the Location ...
                </h6>
                <h2>
                  {code}-{phone}
                </h2>
                <div className="loc-text">
                  <span>Carrier</span>
                  <span >{details?.carrier}</span>
                </div>
                <div className="loc-text">
                  <span>Country</span>
                  <span >{userLocation.country}</span>
                </div>
                <div className="loc-text">
                  <span>Time Zone</span>
                  <span className="blur">IST(UTC + 5:30)</span>
                </div>
                <div className="loc-text">
                  <span>City</span>
                  <span className="blur">Turkey</span>
                </div>

                <div className="loc-text">
                  <span>Instagram</span>
                  <span className="blur">@John Doe</span>
                </div>

                <div className="loc-text">
                  <span>Facebook</span>
                  <span className="blur">@JohnDoe112</span>
                </div>

                <div className="loc-text">
                  <span>Tinder</span>
                  <span className="blur">@12JohnDoe</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Location;
