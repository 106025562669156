import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../axios-client";
import iziToast from "izitoast";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(email);
        const { data } = await axiosClient.post("/subscribers", {email});
        console.log(data);
        if(data.status == "true"){
          iziToast.success({
            message: data.message,
            position: "topCenter"
          });
        }
        else{
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
    } catch (err) {
      iziToast.error({
        message: err,
        position: "topCenter"
      });
      console.error(err);
    }
  };
  return (
    <>
      <div className="container-fluid footer-top">
        <div className="container">
          <div className="row f-row">
            <div className="col-lg-4">
              <h3>Join Our Newsletter to Keep Up To Date With Us!</h3>
            </div>
            <div className="col-lg-6">
              <form action="" className="row">
                <div className="col-lg-8">
                  <input type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter Your Email" />
                </div>
                <div className="col-lg-4">
                  <button onClick={handleSubmit}>Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row mb-4">
            <div className="col-lg-6 footer-col">
              <div className="col-lg-8">
                <div className="footer-logo">
                  <img src="images-local/logo.png" alt="" />
                </div>
                <p>
                  Locating someone has never been easier. Generate a link in
                  your account and share it via any messenger, SMS, or email.
                </p>
                <div className="footer-social">
                  <i className="bi bi-facebook"></i>
                  <i className="bi bi-twitter"></i>
                  <i className="bi bi-instagram"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-6 row">
              <div className="col-lg-4 footer-col">
                <h3>Company</h3>
                <div>
                <Link to="/contact" className="text-decoration-none">
                  <li>Contact</li>
                  </Link>
                  <Link to="/cookie-policy" className="text-decoration-none">
                    <li>Cookie Policy</li>
                  </Link>
                  {/* <Link to="/location" className="text-decoration-none">
                  <li>Location</li>
                  </Link> */}
                  {/* <li>Contact</li>
                  <li>Services</li>
                  <li>Location</li>
                  <li>Pricing</li> */}
                
                </div>
              </div>
              {/* <div className="col-lg-4 footer-col">
                <h3>Resources</h3>
                <div>
                  <li>Location</li>
                  <li>Video</li>
                  <li>How to -Fetch</li>
                  <li>FAQ</li>
                </div>
              </div> */}
              <div className="col-lg-4 footer-col">
                <h3>Help</h3>
                <div>
                  {/* <li>Customer Support</li> */}
              
                  <Link to="/tnc" className="text-decoration-none">
                    <li>Terms & Conditions</li>
                  </Link>
                  <Link to="/privacy-policy" className="text-decoration-none">
                    <li>Privacy Policy</li>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>
              © 2024 <b>LOCARADAR</b>. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
