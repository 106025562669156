import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid cookie">
        <div className="container">
          <h1>Privacy Notice</h1>
          <p>
            <b>
              The last update of this Privacy Notice was made on 24 of January,
              2024
            </b>
          </p>
          <p>
            This Privacy Notice describes the privacy practices for the Website
            Locaradar (hereinafter "Website", "Site") operated by AVIWELL
            LIMITED (hereinafter “we”, “us”, “our”, “Company”) and how the
            Website and Locaradar, operated by Company, collect and use the
            personal data you provide to the Company, with the purpose to use
            Locaradar (hereinafter referred to as the “Locaradar”). It also
            describes the choices available to you regarding our use of your
            personal information and how you can access, update and delete this
            information.
          </p>
          <p>
            The use of the Website is possible without any indication of
            personal data; however, if a user wants to use the Locaradar,
            collecting and processing of personal data could become necessary.
            If the processing of personal data is necessary, we generally obtain
            consent from the user as a data subject, except cases when personal
            data processing is available under other grounds (legitimate
            interests, contract executing, etc.).
          </p>
          <p>
            The processing of personal data, such as the address, e-mail
            address, telephone number or billing information of a data subject
            shall always be in line with the General Data Protection Regulation
            (GDPR), and in accordance with the country-specific data protection
            regulations applicable to Company. By means of this Privacy Notice,
            we would like to inform the general public of the nature, scope, and
            purpose of the personal data we collect, use and process.
            Furthermore, data subjects (users) are informed, by means of this
            Privacy Notice, of the rights to which they are entitled.
          </p>
          <p>
            This Privacy Notice is an integral part of the Terms of Use <br />
            As the controller, Company has implemented numerous technical and
            organizational measures to ensure the most complete protection of
            personal data processed through this Website. In this Privacy Notice
            AVIWELL LIMITED (registered address: Nikou Pattichi, 36, 3071,
            Limassol, Cyprus, HE 432943) is identified as the Controller of
            personal data.
          </p>
          <h4>COOKIES</h4>
          <p>
            A cookie is a small text file which is placed onto your device (e.g.
            computer, smartphone or other electronic device) when you visit our
            website to store a range of information, for example, your language
            preference, or browser and device you are using to view the website.
            Those cookies are set by us and called first-party cookies. We also
            use third-party cookies, which are cookies from a domain different
            from the domain of our website, for our advertising and marketing
            efforts.
          </p>
          <p>
            Session cookies expire each time you close your browser and do not
            remain on your device afterwards. These cookies allow our website to
            link your actions during a particular browser session. <br />{" "}
            Persistent cookies will remain on your device for a period of time,
            and will expire on a set expiration date, or when you delete them
            manually from your cache. These cookies are stored on your device in
            between browser sessions and allow your preferences and actions
            across our website to be remembered.
          </p>
          <div className="row">
            <div className="col-lg-6 p-3">
              <h4>DEFINITIONS</h4>
              <p>
                <b>Personal data</b> means any information relating to an
                identified or identifiable natural person (“data subject/user”).
                An identifiable natural person is one who can be identified,
                directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person.
              </p>
              <p>
                <b>Controller</b> for the purposes of the General Data
                Protection Regulation (GDPR), other data protection laws
                applicable in Member states of the European Union and other
                provisions related to data protection is the Company.
              </p>
              <p>
                <b>Processing</b> is any operation or set of operations which is
                performed on personal data or on sets of personal data, whether
                or not by automated means, such as collection, recording,
                organization, structuring, storage, adaptation or alteration,
                retrieval, consultation, use, disclosure by transmission,
                dissemination or otherwise making available, alignment or
                combination, restriction, erasure or destruction.
              </p>
              <p>
                <b>Pseudonymization</b> is the processing of personal data in
                such a manner that the personal data can no longer be attributed
                to a specific data subject/user without the use of additional
                information, provided that such additional information is kept
                separately and is subject to technical and organizational
                measures to ensure that the personal data are not attributed to
                an identified or identifiable natural person.
              </p>
              <p>
                <b>Encryption</b> is a security protection measure for personal
                data; as a form of cryptography, it is a process whereby
                personal data gets turned into an encoded and unintelligible
                version, using encryption algorithms and an encryption key, and
                whereby a decryption key or code enables users to decode it
                again.
              </p>
              <p>
                <b>Consent</b> of the data subject/user is any freely given,
                specific, informed and unambiguous indication of the data
                subject/user`s wishes by which he or she, by a statement or by a
                clear affirmative action, signifies agreement to the processing
                of personal data relating to him or her.
              </p>
            </div>
            <div className="col-lg-6 p-3">
              <h4>PERSONAL DATA COLLECTION AND USE</h4>
              <p>
                Anyone can access our Website without necessity to provide its
                personal data. <br />
                The purpose of processing your personal data by the Company and
                collection of information about you are as follows (the
                “Purpose”):
                <ul>
                  <li>Your intention to use Locaradar;</li>
                  <li>
                    For the performance of a contract to which the user is a
                    party, in accordance with Article 6.1.b of the GDPR and the
                    collected data are necessary in order for Locaradar to
                    identify and bill the user and to charge its bank card
                    number;
                  </li>
                  <li>
                    For verifying your identity and providing customer care
                    service and assistance;
                  </li>
                  <li>
                    Analyze your use of our Website and Locaradar to better
                    understand how they are being used so we can improve our
                    Services and the user experience, and engage and retain
                    users.
                  </li>
                </ul>
              </p>
              <p>
                We may use your Personal data to: (a) improve your browsing
                experience by personalizing the Website and to improve the
                Locaradar; (b) send information to you by email regarding
                registration status, password verification, payment
                confirmation; (c) send you communications relating to your use
                of the Locaradar, and (d) provide our partners with
                statistical information about our users by secured channels
                under data processing agreements (DPA); (e) send you marketing
                and promotional materials and messages.
              </p>
              <p>
                As you are willing to use Locaradar you are required to
                provide your personal data (to register an account and purchase
                the Locaradar), thus becoming a user of Locaradar.
                <br />
                Please be aware that you are not obliged to provide us with your
                personal data. However, if you do not provide us with your email
                address and payment information, you may not be able to create
                an account to log into and make a purchase of Locaradar.
              </p>
            </div>
          </div>
          <h4>
            SCOPE AND CATEGORIES OF PERSONAL DATA, LAWFUL BASIS FOR PERSONAL
            DATA PROCESSING AND DATA SUBJECT CATEGORIES
          </h4>
          <p>
            We strive to be compliant with GDPR and implemented data
            minimization principles.
          </p>
          <div className="cookie-table">
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Scope and categories of personal data</th>
                  <th>Data subject categories</th>
                  <th>Processing purpose</th>
                  <th>Lawful basis for personal data processing</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>__cfruid</td>
                  <td>3d party</td>
                  <td>cloudflare.com</td>
                  <td>
                    This cookie is used by service Cloud Flare and required to
                    manage incoming traffic and to have better visibility on the
                    origin of a particular request
                  </td>
                  <td>Session</td>
                </tr>
                <tr>
                  <td>__cfruid</td>
                  <td>3d party</td>
                  <td>cloudflare.com</td>
                  <td>
                    This cookie is used by service Cloud Flare and required to
                    manage incoming traffic and to have better visibility on the
                    origin of a particular request
                  </td>
                  <td>Session</td>
                </tr>
                <tr>
                  <td>__cfruid</td>
                  <td>3d party</td>
                  <td>cloudflare.com</td>
                  <td>
                    This cookie is used by service Cloud Flare and required to
                    manage incoming traffic and to have better visibility on the
                    origin of a particular request
                  </td>
                  <td>Session</td>
                </tr>
                <tr>
                  <td>__cfruid</td>
                  <td>3d party</td>
                  <td>cloudflare.com</td>
                  <td>
                    This cookie is used by service Cloud Flare and required to
                    manage incoming traffic and to have better visibility on the
                    origin of a particular request
                  </td>
                  <td>Session</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            The storage of this data takes place against the background that
            this is the only way to prevent the misuse of Locaradar, and, if
            necessary, to make it possible to investigate committed offenses.
            <br />
            The personal data are not transferred to third parties, except
            events, when it’s necessary with an aim to provide Locaradar
            and/or there is a lawful obligation to transfer these data under
            request of government authorities and/or the transferring is
            executed in respect of criminal proceedings. At the same time,
            taking into account the principles of data protection and the
            provisions of personal data protection laws, you understand,
            acknowledge and accept that we have the right, and sometimes the
            lawful obligation, to provide the holder of the telephone number
            whose location is being sought (target device holder) by you with
            the following information about you (as a Locaradar user) upon
            request of such holder: IP address; Email; Browser information
            (Browser name, version); Device type (Web, Mob, Laptop); OS
            (Windows, Android, MacOS); Browser language; Device screen size.
            <br />
            We do not authorize the use of your personal data by any third party
            (only under exceptional conditions as described under "Legal
            Matters" below). We operate and maintain a variety of online
            security measures to safeguard and keep your personal information
            private and secured.
            <br />
            When you purchase the Locaradar you have to fill the User’s
            Information, which should contain your personal information as
            email, which is processed and stored by us
            <br />
            You also have to fill the Payment Information, which should contain
            your Personal data, which is partly processed by us and processed by
            the payment providers.
          </p>
          <h4>MISCELLANEOUS</h4>
          <p>
            We may modify this Privacy Notice at any time and post any changes
            to the Privacy Notice on the Website, so please review it
            frequently. We indicate the date of the current version of this
            Privacy Notice below, so you know when it was last updated.
            <br />
            Changes to this Privacy Notice may not affect your personal data we
            have previously collected from you or after such changes.
            <br />
            If you object to the changes, please contact us as provided below.
            <br />
            This Privacy Notice is governed by and construed with the laws of
            Cyprus.
            <br />
            The Cyprus courts shall have exclusive jurisdiction to settle any
            claim or dispute which might arise out of or in connection with this
            Privacy Notice.
            <br />
            If you have any questions or propositions, please, get in touch with
            us by the e-mail.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
