import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Cookie = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid cookie">
        <div className="container">
          <h1>Cookie Policy</h1>
          <p>
            This Cookie Policy explains how we use cookies and other similar
            technologies when you use our service, what tracking technologies
            are and why we use them. It also explains how you can control their
            use.
          </p>
          <h4>COOKIES</h4>
          <p>
            A cookie is a small text file which is placed onto your device (e.g.
            computer, smartphone or other electronic device) when you visit our
            website to store a range of information, for example, your language
            preference, or browser and device you are using to view the website.
            Those cookies are set by us and called first-party cookies. We also
            use third-party cookies, which are cookies from a domain different
            from the domain of our website, for our advertising and marketing
            efforts.
          </p>
          <p>
            Session cookies expire each time you close your browser and do not
            remain on your device afterwards. These cookies allow our website to
            link your actions during a particular browser session. <br />{" "}
            Persistent cookies will remain on your device for a period of time,
            and will expire on a set expiration date, or when you delete them
            manually from your cache. These cookies are stored on your device in
            between browser sessions and allow your preferences and actions
            across our website to be remembered.
          </p>
          <h4>SIMILAR TECHNOLOGIES</h4>
          <p>
            In addition to cookies, there are other similar technologies that
            may be used on the web or in mobile ecosystems.
          </p>
          <ul>
            <li>
              Web beacon is a small, transparent image (also known as “pixel
              tag”, “web bug”, “GIF”) that contains a unique identifier. It is
              embedded into a website or an email. When your browser reads the
              code of the website it communicates with our server in order to
              display the image and, through this process, we are able to
              acquire information such as technical specifications of your
              device, operating systems and settings being used. It can also
              track your activities during a session. Also, it enables us to
              recognize when each email was opened, from what IP address and
              from what device. We use this information to improve our email
              communication.
            </li>
            <li>
              Software development kits (SDK) are third-party software
              development kits that may be installed in our mobile applications.
              SDKs help us understand how you interact with our mobile
              applications and collect certain information about the device and
              network you use to access the application.
            </li>
            <li>
              Local shared objects, commonly called “Flash cookies”, are pieces
              of data that websites which use Adobe Flash may store on a user's
              computer to facilitate the Flash functionality. We may use Adobe
              Flash to display graphics, interactive animations and other
              enhanced functionality. Local shared objects can track similar
              parameters to cookies, but they can also provide information on
              your use of the specific feature that the cookie is enabling. For
              example, if it facilitates the display of a video then we can
              receive details of how much of the video was watched and when
              viewing stopped. Flash cookie management tools can be accessed
              directly via www.adobe.com.
            </li>
            <li>
              HTML5 local storage. HTML 5 is the fifth version of the HTML
              language and contains functionality that allows information to be
              stored locally within the browser’s data files. HTML5 local
              storage operates in a similar way to cookies but differs in that
              it can hold greater amounts of information and does not rely on an
              exchange of data with the website’s server.
            </li>
            <li>
              Fingerprinting is a technique that involves combining a set of
              information elements in order to uniquely identify a particular
              device. These information elements include, for example: data from
              configuration of the device, CSS information, JavaScript objects,
              installed fonts, installed plugins with the browser, use of any
              APIs, HTTP header information, clock information.
            </li>
            <li>
              Device Identifiers are identifiers comprised of numbers and
              letters, which are unique to each specific device. These include
              Apple’s ID for Advertisers (IDFA) and Google’s Android Advertising
              ID (AAID). They are stored on the device and are used to recognize
              you and your devices across different apps and devices for
              marketing and advertising purposes. You can reset your device
              identifier or opt out of personalized advertising in the settings
              of your device.
            </li>
            <li>
              Social widgets are buttons or icons provided by third-party social
              media providers that allow you to interact with those social media
              services when you view a web page or a mobile app screen. These
              social widgets may collect browsing data, which may be received by
              the third party that provided the widget, and are controlled by
              the third parties.
            </li>
          </ul>
          <h4>HOW CAN YOU MANAGE YOUR COOKIES AND SIMILAR TECHNOLOGIES?</h4>
          <ol>
            <li>
              <b> Browser and device settings</b>{" "}
              <p>
                Most browsers allow you to refuse to accept cookies and to
                delete cookies. The methods for doing so vary from browser to
                browser, and from version to version. You can however obtain
                up-to-date information about blocking and deleting cookies via
                these links:
              </p>
              <ul>
                <li>Chrome</li>
                <li>Firefox</li>
                <li>Opera</li>
                <li>Internet Explorer</li>
              </ul>
              <p>
                Detailed instructions on how to control your cookies through
                browser settings can also be found here:{" "}
                <a href="https://www.aboutcookies.org/how-to-control-cookies/">
                  https://www.aboutcookies.org/how-to-control-cookies/
                </a>
                <br />
                Please note that blocking all cookies will have a negative
                impact on the usability of many websites. If you block cookies,
                you will not be able to use all the features on our website. To
                reset your device identifier or opt-out of personalized
                advertising, follow Google instructions or Apple instructions.
                <br />
                To opt out of data usage by Google Analytics follow
                instructions:{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
            </li>
            <li>
              <b>Opt-out of internet-based advertising</b>{" "}
              <p>
                The third-party advertisers, ad agencies and vendors with which
                we work may be members of the Network Advertising Initiative,
                the Digital Advertising Alliance Self-Regulatory Program for
                Online Behavioural Advertising, the European Digital Advertising
                Alliance. To opt-out of interest-based advertising from the
                participating companies, visit the following links:
              </p>
              <ul>
                <li>
                  Network Advertising Initiative{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  Network Advertising Initiative{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  Network Advertising Initiative{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  Network Advertising Initiative{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
              </ul>
            </li>
          </ol>
          <h4>COOKIES LIST</h4>
          <p>
            You can find more information about the individual cookies we use
            and the purposes for which we use them in the table below:
          </p>
          <ol>
            <li>
              <b>Necessary cookies : </b> These cookies are necessary for the
              website to function and cannot be switched off in our systems.
              They are usually only set in response to actions made by you which
              amount to a request for services, such as setting your privacy
              preferences, logging in or filling in forms. You can set your
              browser to block or alert you about these cookies, but some parts
              of the site will not then work.
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>Cookie</th>
                      <th>Type</th>
                      <th>Provider</th>
                      <th>Purpose</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <b>Functional cookies : </b> These cookies are necessary for the
              website to function and cannot be switched off in our systems.
              They are usually only set in response to actions made by you which
              amount to a request for services, such as setting your privacy
              preferences, logging in or filling in forms. You can set your
              browser to block or alert you about these cookies, but some parts
              of the site will not then work.
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>Cookie</th>
                      <th>Type</th>
                      <th>Provider</th>
                      <th>Purpose</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <b>Analytical or performance cookies : </b> These cookies are
              necessary for the website to function and cannot be switched off
              in our systems. They are usually only set in response to actions
              made by you which amount to a request for services, such as
              setting your privacy preferences, logging in or filling in forms.
              You can set your browser to block or alert you about these
              cookies, but some parts of the site will not then work.
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>Cookie</th>
                      <th>Type</th>
                      <th>Provider</th>
                      <th>Purpose</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                    <tr>
                      <td>__cfruid</td>
                      <td>3d party</td>
                      <td>cloudflare.com</td>
                      <td>
                        This cookie is used by service Cloud Flare and required
                        to manage incoming traffic and to have better visibility
                        on the origin of a particular request
                      </td>
                      <td>Session</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
          <h4>CHANGES TO THIS POLICY</h4>
          <p>
            We may change this policy from time to time, when we do, we will
            inform you by updating the “Last updated” date below.
            <br />
            <b>Last updated: 24.01.2024</b>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Cookie;
