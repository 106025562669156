import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const LocationDetail = () => {
  const [profileTab, setProfileTab] = useState("details");
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState(["+90"]);
  const location = useLocation();
  // const { code } = location.state || {};
  // const { phone } = location.state || {};
  const { code = "+90" } = location.state || {}; 
  const { phone = "" } = location.state || {};
  const [newphone, setPhone] = useState(null);
  const [newcode, setCode] = useState(code);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ophone, setOphone] = useState(phone);
  const [phoneHistory, setPhoneHistory] = useState({});
  const [phoneLookupHistory, setPhoneLookupHistory] = useState(null);
  const [lookupCompleted, setLookupCompleted] = useState(false); 

  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
    full_address: "",
    longitude: "",
    latitude: "",
    timezone: ""
  });

  const historyData = async () => {
    try {
      const { data } = await axiosClient.get(
        "/fetch-phone-history",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setPhoneHistory(data.data.history);
    }
    catch (err) {
      console.log(err);
    }
  }


  const phoneLookupData = async () => {
    try {
      const { data } = await axiosClient.get(
        `/phone-lookup/${newcode}${ophone}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("data phone code ", data.carrier);
      setPhoneLookupHistory(data.carrier);
      setLookupCompleted(true); 
    } catch (err) {
      console.log(err);
      setLookupCompleted(false); 
    }
  };
  useEffect(() => {
    historyData();
    phoneLookupData();
  }, []);

  useEffect(() => {
    console.log(phoneHistory);
  }, [phoneHistory]);

  const userData = async () => {
    if (phone) {
      try {
        const { data } = await axiosClient.post(
          "/fetch-location",
          {
            phone_number: phone
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        if(data.status === "pending"){
          iziToast.error({
            message: "Location access is Pending.",
            position: "topCenter"
          });
        }
        else if(data.status === "false"){
          iziToast.error({
            message: "Location access is Denied by User.",
            position: "topCenter"
          });
        }
        setUserLocation(data);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
      }
      catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    userData();
  }, [phone]);

  
  const handleChange = (e) => {
    setPhone(e.target.value);
    setOphone(e.target.value);
  }
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  }
  const findCode = async () => {
    const countrydata = await axios.get("https://restcountries.com/v3.1/all");
    const codes = countrydata.data.flatMap((country) => {
      if (country.idd && country.idd.root && country.idd.suffixes) {
        // Combine root with each suffix to form full codes
        return country.idd.suffixes.map((suffix) => `${country.idd.root}${suffix}`);
      }
      return []; // Return an empty array if root or suffixes are not available
    });
    setCountryCodes(codes);
    console.log(codes);
  }
  useEffect(() => {
    findCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newcode === null) {
      iziToast.error({
        message: "Please Select Code.",
        position: "topCenter"
      });
    }
    else if (newphone.length !== 10 && isNaN(newphone)) {
      iziToast.error({
        message: "Please enter valid phone number.",
        position: "topCenter"
      });
    } else {
      try {
        console.log(newphone);
        console.log(newcode);
        const { data } = await axiosClient.post(
          "/send-location-link",
          {
            phone_number: newphone,
            country_code: newcode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        if (data.status !== "false") {
          iziToast.success({
            message: data.message,
            position: "topCenter"
          });
          var phone = newphone;
          var code = newcode;
          navigate('/location-detail', { state: { phone, code } });
        }
        else {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  if (latitude) {
    console.log(latitude, longitude);
    var mapcontainer = <>
      <MapContainer
        center={[latitude, longitude]} // Center the map to a specific latitude and longitude
        zoom={16} // Zoom level
        style={{
          height: '100vh', // Full height for the map
          width: '100%', // Full width for the map
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Using OpenStreetMap tiles
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitude, longitude]}>
          <Popup>
            latitude: {latitude}, longitude: {longitude}
          </Popup>
        </Marker>
      </MapContainer>
    </>
  }
  else {
    mapcontainer = "";
  }

  const handleProfileTab = (tab) => {
    setProfileTab(profileTab === tab ? null : tab);
  };

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>

      <div className="container-fluid" style={{ 'position': 'relative', 'min-height': '100vh' }}>
        {mapcontainer}
        {/* <div className="container">
          <div className="row"> */}
        <div className="col-lg-4" style={{ 'position': 'absolute', 'left': '70px', 'top': '20px', 'z-index': '999' }}>
          <div className="detail-search">
            <h5>Find location of :</h5>
            <form className="search-location row" onSubmit={handleSubmit}>
              <div className="col-lg-8">
                <div className="location-group">
                {lookupCompleted ? (

                      <option value={newcode}>
                        {newcode}
                      </option>

            ) : (
                  <select name="" onChange={handleCodeChange} id="">
                    <option value=""></option>
                    {countryCodes.map((pcode) => (
                      <option key={pcode} value={pcode} selected={pcode == code}>
                        {pcode}
                      </option>
                    ))}
                  </select>
                )}
                {lookupCompleted ? (
              <input type='text' value={ophone} />
            ) : (
                  <input type="text" onChange={handleChange} value={ophone} maxLength="10" minLength="10" placeholder="Enter Phone Number" required/>
                )}
                </div>
              </div>
              <div className="col-lg-4">
        {!lookupCompleted 
        && 
        <button type="submit">Locate</button>}
      </div>
            </form>
          </div>
          <div className="detail-search">
            <h5>Find location of :</h5>
            <div className="profile-tabs">
              <button
                className={`profile-links ${profileTab === "details" ? "profile-active" : ""
                  }`}
                onClick={() => handleProfileTab("details")}
              >
                Details
              </button>
              <button
                className={`profile-links ${profileTab === "recent" ? "profile-active" : ""
                  }`}
                onClick={() => handleProfileTab("recent")}
              >
                Recent
              </button>
            </div>
            {profileTab === "details" && (
              <div>
                <div className="row p-4">
                  {/* <div className="col-lg-6">
                        <div className="profile-item">
                          <h6>First Name</h6>
                          <p>Charles</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="profile-item">
                          <h6>Last Name</h6>
                          <p>Leclerc</p>
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="profile-item">
                          <h6>Carrier</h6>
                          {phoneLookupHistory ? (
  <p> {phoneLookupHistory}</p>
) : (
  <p>Loading phone lookup data...</p>
)}
                        </div>
                      </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>Country</h6>
                      <p>{userLocation.country}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>City</h6>
                      <p>{userLocation.city}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>Time Zone</h6>
                      <p>{userLocation.timezone}</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="profile-item">
                      <h6>Address</h6>
                      <p>{userLocation.full_address}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="footer-social">
                      <Link>
                        <div className="social-img">
                          <img src="images-local/instagram.png" alt="" />
                        </div>
                      </Link>
                      <Link>
                        <div className="social-img">
                          <img src="images-local/facebook.png" alt="" />
                        </div>
                      </Link>
                      <Link>
                        <div className="social-img">
                          <img src="images-local/tinder.png" alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {profileTab === "recent" && <div>
              <div className="row mt-4">
                {phoneHistory.map((phistory) => {
                  const phone = phistory.phone_number;
                  const code = phistory.country_code || "+90";
                  const formattedDate = new Date(phistory.created_at).toLocaleString('en-US', {
                    year: 'numeric', // Year (e.g., '2024')
                    month: 'long', // Month name (e.g., 'August')
                    day: 'numeric', // Day of the month (e.g., '29')
                    hour: '2-digit', // Hour (e.g., '09')
                    minute: '2-digit', // Minute (e.g., '05')
                    second: '2-digit', // Second (e.g., '30')
                  });
                  return (
                    <div className="col-lg-12">
                      <div className="history-card" onClick={()=>navigate("/location-detail", { state: {phone, code} })}>
                        <div>
                          <h6>{phistory.country_code}-{phistory.phone_number}</h6>
                          <p>Located on {formattedDate}</p>
                        </div>
                        <i className="bi bi-info-circle"></i>
                      </div>
                    </div>)
                })}
                {/* <div className="col-lg-12">
                      <div className="history-card">
                        <div>
                          <h6>+91-8989898999</h6>
                          <p>Located on July 24, 2024 at 2:00PM IST</p>
                        </div>
                        <i className="bi bi-info-circle"></i>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="history-card">
                        <div>
                          <h6>+91-8989898999</h6>
                          <p>Located on July 24, 2024 at 2:00PM IST</p>
                        </div>
                        <i className="bi bi-info-circle"></i>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="history-card">
                        <div>
                          <h6>+91-8989898999</h6>
                          <p>Located on July 24, 2024 at 2:00PM IST</p>
                        </div>
                        <i className="bi bi-info-circle"></i>
                      </div>
                    </div> */}
                <p className="view"><Link to="/profile" className="text-white">View All</Link></p>
              </div>
            </div>}
          </div>
        </div>
        {/* </div>
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default LocationDetail;