import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid cookie">
        <div className="container">
          <h1>TERMS OF USE</h1>
          <p>
            <b>
              The last update of these Terms of Use was made on April 25, 2024
              <br />
              PLEASE READ THESE TERMS OF USE CAREFULLY.
              <br />
              THESE TERMS OF USE ARE BINDING.
            </b>
          </p>
          <p>
            Welcome to the Locaradar website (hereinafter - the “Site”,
            “Website”). This website is operated by AVIWELL LIMITED, registered
            address: Nikou Pattichi, 36, 3071, Limassol, Cyprus, HE 432943
            (hereinafter referred to as “We,” “Us,” “Our”, “Company”).
          </p>
          <p>
            These Terms of Use (hereinafter - the “ToU”) describe the terms and
            conditions applicable to your access and use of the Site, the
            Locaradar SaaS (Software as a Service) (hereinafter - the
            “Locaradar”) and services, that are provided by Company
            (hereinafter collectively “Locaradar Services”) made available
            through or in connection with the Site.
          </p>
          <p>
            By using the Website, Locaradar and/or Locaradar Services, you
            agree to be bound by the following Terms of Use.
            <br />
            The integral parts of these Terms of Use are Privacy Notice and
            Cookie Policy.
          </p>
          <p>
            YOUR USE OF THE SITE, Locaradar AND/OR Locaradar SERVICES
            SIGNIFIES THAT YOU AGREE TO THESE TOU AND CONSTITUTES YOUR BINDING
            ACCEPTANCE OF THESE TOU, INCLUDING ANY MODIFICATIONS THAT WE MAKE
            FROM TIME TO TIME. WE WILL INFORM YOU ABOUT ANY MODIFICATIONS THAT
            OCCUR IN THE ESSENTIAL PARTS OF THE TOU AND WILL ASK IMPLIED
            CONSENT. THIS MEANS THAT IF YOU CONTINUE TO USE THE SOFTWARE AND THE
            Locaradar SERVICES, YOU AGREE WITH THESE MODIFICATIONS.
            <br />
            BY AGREEING TO THESE TOU, YOU AS THE HOLDER OF THE TELEPHONE NUMBER
            (OR IN CASE IT IS YOUR CHILDREN'S TELEPHONE NUMBER, AS LONG AS THEY
            HAVE NOT REACHED THE AGE OF ADULTHOOD IN YOUR COUNTRY OR PERSONS'
            TELEPHONE NUMBER THAT YOU HAVE AUTHORIZED TO TRACE YOUR CHILDREN'S
            MOBILE) GIVE YOUR CONSENT TO RECEIVE SMS TO THE RESPECTIVE TELEPHONE
            NUMBER THAT YOU SPECIFIED VIA SERVICES.
          </p>
          <h4>DEFINITIONS</h4>
          <p>
            Locaradar - a geolocation SaaS (Software as a Service) allowing any
            individual acting in a private capacity to locate a mobile phone
            with the prior consent of the holder of the telephone number whose
            location is sought.
            <br />
            Subscription – recurring payments for Locaradar usage on a periodic
            basis.
            <br />
            Target device – a smartphone, tablet, mobile phone or another
            similar device, which is tracked by you through Locaradar.
            <br />
            Payment information – full username, credit / debit card number,
            CVV, card expiry date and other information you may need to pay for
            Locaradar.
            <br />
            Payment service provider – a financial institution, which processes
            payments for Locaradar.
            <br />
            Subscription month – paid subscription period that consists of 28
            calendar days. This applies to 1- and 6-months subscription plans.
          </p>

          <div className="row">
            <div className="col-lg-6 p-3">
              <h4>DEFINITIONS</h4>
              <p>
                <b>Personal data</b> means any information relating to an
                identified or identifiable natural person (“data subject/user”).
                An identifiable natural person is one who can be identified,
                directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person.
              </p>
              <p>
                <b>Controller</b> for the purposes of the General Data
                Protection Regulation (GDPR), other data protection laws
                applicable in Member states of the European Union and other
                provisions related to data protection is the Company.
              </p>
              <p>
                <b>Processing</b> is any operation or set of operations which is
                performed on personal data or on sets of personal data, whether
                or not by automated means, such as collection, recording,
                organization, structuring, storage, adaptation or alteration,
                retrieval, consultation, use, disclosure by transmission,
                dissemination or otherwise making available, alignment or
                combination, restriction, erasure or destruction.
              </p>
              <p>
                <b>Pseudonymization</b> is the processing of personal data in
                such a manner that the personal data can no longer be attributed
                to a specific data subject/user without the use of additional
                information, provided that such additional information is kept
                separately and is subject to technical and organizational
                measures to ensure that the personal data are not attributed to
                an identified or identifiable natural person.
              </p>
              <p>
                <b>Encryption</b> is a security protection measure for personal
                data; as a form of cryptography, it is a process whereby
                personal data gets turned into an encoded and unintelligible
                version, using encryption algorithms and an encryption key, and
                whereby a decryption key or code enables users to decode it
                again.
              </p>
              <p>
                <b>Consent</b> of the data subject/user is any freely given,
                specific, informed and unambiguous indication of the data
                subject/user`s wishes by which he or she, by a statement or by a
                clear affirmative action, signifies agreement to the processing
                of personal data relating to him or her.
              </p>
            </div>
            <div className="col-lg-6 p-3">
              <h4>PERSONAL DATA COLLECTION AND USE</h4>
              <p>
                Anyone can access our Website without necessity to provide its
                personal data. <br />
                The purpose of processing your personal data by the Company and
                collection of information about you are as follows (the
                “Purpose”):
                <ul>
                  <li>Your intention to use Locaradar;</li>
                  <li>
                    For the performance of a contract to which the user is a
                    party, in accordance with Article 6.1.b of the GDPR and the
                    collected data are necessary in order for Locaradar to
                    identify and bill the user and to charge its bank card
                    number;
                  </li>
                  <li>
                    For verifying your identity and providing customer care
                    service and assistance;
                  </li>
                  <li>
                    Analyze your use of our Website and Locaradar to better
                    understand how they are being used so we can improve our
                    Services and the user experience, and engage and retain
                    users.
                  </li>
                </ul>
              </p>
              <p>
                We may use your Personal data to: (a) improve your browsing
                experience by personalizing the Website and to improve the
                Locaradar; (b) send information to you by email regarding
                registration status, password verification, payment
                confirmation; (c) send you communications relating to your use
                of the Locaradar, and (d) provide our partners with
                statistical information about our users by secured channels
                under data processing agreements (DPA); (e) send you marketing
                and promotional materials and messages.
              </p>
              <p>
                As you are willing to use Locaradar you are required to
                provide your personal data (to register an account and purchase
                the Locaradar), thus becoming a user of Locaradar.
                <br />
                Please be aware that you are not obliged to provide us with your
                personal data. However, if you do not provide us with your email
                address and payment information, you may not be able to create
                an account to log into and make a purchase of Locaradar.
              </p>
            </div>
          </div>
          <h4>GENERAL PROVISIONS</h4>
          <p>
            You are required to create an account on the Site for purchasing and
            usage of Locaradar. For the account creating you have to provide
            your email address, password and Payment information.
            <br />
            When placing the order for the Locaradar, you acknowledge and
            agree that you have reached the legal age of adulthood in your
            country.
            <br />
            You are aware that We are not responsible for any third-party data
            leak in databases and/or DSN servers.
            <br />
            You will not use the Site or Locaradar in violation of any laws
            you are subject to. Notwithstanding the foregoing, We are not
            responsible for the further use of the Locaradar and it’s your
            sole responsibility.
            <br />
            You will properly follow all instructions and documentation provided
            by us to you at the Website.
            <br />
            You will not allow any other third party to access your account or
            to utilize the Locaradar. In case access to your account has been
            granted to a third party deliberately, or by mistake, or in any
            other way without your consent, you acknowledge that we don’t bear
            the responsibility.
            <br />
            You may not share or transfer any account. You may not disclose your
            password to anyone else.
            <br />
            You must notify us immediately of any breach of security or
            unauthorized use of your account or if you believe that an account
            password may have been compromised.
            <br />
            We will not be liable for any losses caused by any unauthorized use
            of your account.
            <br />
            We reserve the right to modify, terminate, or refuse the Locaradar
            Services to anyone for any reason and without notice at any time.
            <br />
            You understand that we, in our sole discretion, may at any time for
            any reason suspend or terminate any license thereunder and disable
            the functionality of the Locaradar. In this case, the information
            you may have already accessed will become unavailable without prior
            notice.
            <br />
            In case you have any technical question, please contact customer
            service at support@Locaradar. Once your subscription has been
            confirmed, all you have to do is indicate the number of the mobile
            phone you wish to geo-locate ("Recipient"). An SMS is then sent to
            the Recipient containing the message that You will have previously
            written and stating his wish to geo-locate the mobile phone in
            question. The Recipient is then free to accept or refuse this
            request, at his or her sole discretion. You acknowledge that We are
            not able to guarantee that the Recipients will accept your
            geo-location request, and that any refusal will not give rise to any
            liability or right to any reimbursement whatsoever on Our part in
            respect of You. The recipient of the SMS can be your own phone or
            your children's phone as long as they have not reached the age of
            adulthood in your country and persons you have authorized to trace
            their mobile. We may propose and resale third-parties’ products
            (software, mobile application, etc.) and use third-parties’
            services. You may buy it with or separately from main subscriptions.
            Terms and conditions, privacy matters, end-user license agreement,
            etc., that are related to using of such third-parties’ products /
            services will be provided by such third parties and you can find it
            via links, specified on appropriate pages of Website. You aware that
            as we use third parties services, we have no control over these
            services and We are not responsible for such third-parties’ products
            and your using of such products is only your sole responsibility,
            even if we recommend it. If you have any questions about
            third-parties’ products, its usage, problems with installing, error
            fixing, etc., you should send your request to an appropriate
            provider. We don’t assist in matters on how to install and how to
            use these products, however, you may get necessary information from
            the appropriate provider. Under your request we will provide you
            urgent contact data. For billing and other financial matters, you
            have to get in touch with us via e-mail support@Locaradar. For
            getting and installing third-parties’ products, you have to follow
            instructions that you will receive after you make a payment. These
            instructions will appear on the Website and/or will be sent by
            e-mail.
          </p>

          <h4>MISCELLANEOUS</h4>
          <p>
            You agree to indemnify, defend, and hold the Company and the related
            parties harmless from any and all claims, demands, damages, or other
            losses, including reasonable attorneys’ fees, resulting or arising
            from your use of the Site and Locaradar or any breach by you of
            these ToU or any other policies that we may issue for the Site
            and/or Locaradar usage from time to time.
            <br />
            We may assign these ToU, in whole or in part, to any person or
            entity at any time with or without your consent.
            <br />
            You may not assign the ToU without our prior written consent, and
            any unauthorized assignment by you shall be null and void.
            <br />
            If any part of these ToU is determined to be invalid or
            unenforceable, then that portion shall be severed, and the remainder
            of the ToU shall be given full force and effect.
            <br />
            Failure or delay by the Company to enforce compliance with the ToU
            does not constitute a waiver of these ToU.
            <br />
            By using our Site and/or the Locaradar you confirm that you have
            read and consent to these Terms of Use as well as Privacy Notice and
            Cookie Policy.
            <br />
            We may revise these Terms of Use, Privacy Notice and Cookie Policy
            at any time, without notice, and the new versions will be available
            on the Site.
            <br />
            If at any point you do not agree to any portion of these Terms of
            Use, you should immediately stop using the Site and/or Locaradar.
            <br />
            The express waiver by Locaradar of any provision, condition, or
            requirement of these ToU shall not constitute a waiver of any future
            obligation to comply with such provision, condition or requirement.
            <br />
            All notices given by you or required under this ToU shall be written
            and addressed to:
            <br />
            technical requests shall be addressed to support@locaradar.io.
            <br />
            legal requests shall be addressed to legal@locaradar.io.
            <br />
            All Policies and other documents, which are placed on the Site, are
            integral parts of this Terms of Use.
            <br />
            The Locaradar is offered with the understanding that we may
            terminate any subscription and/or your access to the Locaradar at
            any time, for any appropriate reason, including without limitation
            for any violation of these ToU.
            <br />
            We reserve the right to deny service to, or if applicable, terminate
            the service of any user, including without limitation a user that
            attempts to locate the device of an individual, who has not provided
            their consent. In this case, we reserve the right to block all
            accounts associated with such potential violating user.
            <br />
            These ToU describe certain legal rights. You may have other rights
            under the laws of your state or country. These ToU do not change
            your rights under the laws of your state or country.
            <br />
            This ToU is regulated by the laws of Cyprus. Any disputes should be
            resolved by the appropriate courts of Cyprus.
            <br />
            In case the laws of your state or country do not permit you to use
            the Locaradar, hereof you acknowledge and agree that it is upon
            your responsibility.
            <br />
            The original, legally binding version of this document is written in
            English, and it is translated into other languages for the courtesy
            of our non-English-speaking users. If there are any discrepancies
            between the English version and a translated version, the English
            version supersedes the translated version.
            <br />
            For more information, please contact us by email.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Terms;
