import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OtpTimer from "otp-timer";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";

const OTP = () => {
  const { setToken, setUser, apipath } = useStateContext();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const { reset } = location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
    //  if(reset){
        const { data } = await axiosClient.post("/verify-reset-otp", { email, otp });
        if(data.status !== "false"){
          iziToast.success({
            message: "OTP Verified.",
            position: "topCenter"
          });
        }
     // }
    //  else{
        // const { data } = await axiosClient.post("/verify-otp", { email, otp });
        //  setUser(data.user);
        //  setToken(data.token);
        //  console.log( setToken(data.token));
        // if(data.status !== "false"){
        //   iziToast.success({
        //     message: "OTP Verified.",
        //     position: "topCenter"
        //   });
        // }
    //  }
    //  if(reset){
        navigate("/reset", { state: { email }});
     // }
     // else{
     //   navigate("/payment");
     // }
    } catch (err) {
      console.error(err);
      iziToast.error({
        message: "Invalid OTP or OTP expired. Please try again.",
        position: "topCenter"
      });
      setError("Invalid OTP or OTP expired. Please try again.");
    }
  };

  const handleResendOtp = async () => {
    try {
      if(reset){
        email = reset;
      }
      const response = await axiosClient.post("/resend-otp", { email });
      if (response.status === 200) {
        alert("OTP resent. Please check your email.");
      }
    } catch (err) {
      console.error(err);

      setError("Error resending OTP. Please try again.");
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>Enter OTP</h1>
              <h6>Please enter OTP sent to your mail ID</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="otp">Enter OTP</label>
                  <div className="login-group">
                    <input
                      type={isVisible ? "text" : "password"}
                      name="otp"
                      id="otp"
                      value={otp}
                      placeholder="Enter OTP"
                      onChange={handleOtpChange}
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                  <p>
                    <OtpTimer
                      minutes={0}
                      seconds={60}
                      text={
                        <span className="otp-timer-text">Resend OTP In : </span>
                      }
                      ButtonText={
                        <span className="otp-timer-button">Resend OTP</span>
                      }
                      textColor={"#fff"}
                      buttonColor={"#fff"}
                      background={"transparent"}
                      resend={handleResendOtp}
                    />
                  </p>
                </div>
                {error && <p style={{ color: "#ff8780" }}>{error}</p>}

                <div className="login-btn">
                  <button type="submit">Continue</button>
                </div>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OTP;
