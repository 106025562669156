import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
// import axios from 'axios';
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";

const Register = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   const handleVisibility = () => {
//     setIsVisible(!isVisible);
//   };

const { setToken , setUser} = useStateContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError("This field should not be blank.");
      return;
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

  
    try {
      const { data } = await axiosClient.post("/signup", { email });
      setUser(data.user);
      setToken(data.token);
      if(data.status === "false"){
        iziToast.error({
          message: data.message,
          position: "topCenter"
        });
      }
      else{
        iziToast.success({
          message: "Registered Successfully and your email password sent to your mail",
          position: "topCenter"
        });

        navigate("/payment");
       // navigate("/otp", { state: { email } });
      }
    } catch (err) {
      const response = err.response;
      if (response && response.status === 422) {
        
        if (response.data.errors && response.data.errors.email) {
          setError(response.data.errors.email[0]);
        } else {
          setError("Error signing up, please try again.");
        }
      } else {
        setError("Error signing up, please try again.");
      }
      console.error(err);
    }

  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>Register</h1>
              <h6>Welcome to Locaradar !</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="email">Email</label>
                  <input 
                  type="email" 
                  name="email" 
                  id="email" 
                  placeholder="Enter Email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {/* <div className="login-item">
                  <label htmlFor="">Password</label>
                  <div className="login-group">
                    <input
                      type={isVisible ? "text" : "password"}
                      name=""
                      id=""
                      placeholder="Enter Password"
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                  <p>Forgot Password?</p>
                </div>
                <div className="login-check">
                  <input type="checkbox" name="" id="" />{" "}
                  <label htmlFor="">Remember Me</label>
                </div> */}
                {error && <p style={{color: "#ff8780"}}>{error}</p>}

                <div className="login-btn">
                  {/* <Link to="/otp"><button>Continue</button></Link> */}
                <button type="submit">Continue</button>
                </div>
                <p className="register">
                  Already have an Account ?{" "}
                  <Link to="/login" className="text-decoration-none">
                    <span>Login</span>{" "}
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Register;
