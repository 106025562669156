import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import axios from "axios";

const Tanitum = () => {
  const [showFaq, setShowFaq] = useState(1);
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState([]);

  const { token, apipath } = useStateContext();
  const [phone, setPhone] = useState(null);
  const [code, setCode] = useState("+90");

  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleFaqShow = (index) => {
    setShowFaq(showFaq === index ? null : index);
  };

  const handleChange = (e) => {
    setPhone(e.target.value);
  };
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const findCode = async () => {
    const countrydata = await axios.get("https://restcountries.com/v3.1/all");
    const codes = countrydata.data.flatMap((country) => {
      if (country.idd && country.idd.root && country.idd.suffixes) {
        return country.idd.suffixes.map(
          (suffix) => `${country.idd.root}${suffix}`
        );
      }
      return [];
    });
    setCountryCodes(codes);
    console.log(codes);
  };
  useEffect(() => {
    findCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code === null) {
      iziToast.error({
        message: "Please Select Code.",
        position: "topCenter",
      });
    } else if (
      phone === null ||
      phone.trim() === "" ||
      (phone.length !== 10 && isNaN(phone))
    ) {
      iziToast.error({
        message: "Please enter valid phone number.",
        position: "topCenter",
      });
    } else {
      try {
        const response = await axiosClient.get(`/phone-lookup/${code}${phone}`);
        if (response.data.error) {
          iziToast.error({
            message: response.data.error,
            position: "topCenter",
          });
        } else {
          navigate("/location", {
            state: { phone, code, details: response.data },
          });
        }
      } catch (err) {
        console.error(err);
        iziToast.error({
          message: "An error occurred while fetching details.",
          position: "topCenter",
        });
      }
    }
  };

  return (
    <>
      <Navbar />

      {/* MAIN BANNER */}

      <div className="container-fluid main">
        <div className="container">
          <div className="col-lg-6">
            <h1>
              Never Lose Track Again. Effortless Location Tracking Made Simple.
            </h1>
            <p>
              Never waste time searching for misplaced valuables again. Our
              location tracking solution helps you find what matters most, from
              your phone to your furry friend, instantly.
            </p>

            <form className="search-mobile row mt-5">
              <div className="col-lg-8">
                <div className="search-group">
                  <select
                    name=""
                    value={code}
                    onChange={handleCodeChange}
                    id=""
                  >
                    <option value=""></option>
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    onChange={handleChange}
                    maxLength="15"
                    minLength="10"
                    pattern="\d*"
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <button onClick={handleSubmit}>Locate</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS */}

      <div className="container-fluid works">
        <div className="container">
          <div className="work-header">
            <h1>
              How <span>LOCARADAR</span> works ?
            </h1>
            <p className="col-lg-7 mx-auto">
              Risus commodo id odio turpis pharetra elementum. Pulvinar porta
              porta feugiat scelerisque in elit. Morbi rhoncus, tellus, eros
              consequat magna semper orci a tincidunt.{" "}
            </p>
          </div>
          <div className="row work-row">
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>Create Your Account</h5>
                <p>
                  Egestas tellus nunc proin amet tellus tinciduntl acus
                  consequat. Ultrices
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>Create Your Account</h5>
                <p>
                  Egestas tellus nunc proin amet tellus tinciduntl acus
                  consequat. Ultrices
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>Create Your Account</h5>
                <p>
                  Egestas tellus nunc proin amet tellus tinciduntl acus
                  consequat. Ultrices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS VIDEO */}

      <div className="container-fluid how">
        <div className="container">
          <div className="work-header">
            <h1>
              <span>How Tracking Works</span>
            </h1>
          </div>
          <div className="row work-row">
            <div className="col-lg-6 how-left">
              <h5>
                Track a phone number Right Now with <span>3 easy steps</span> :
              </h5>
              <div className="how-card">
                <h6>Request Location</h6>
                <p>Enter the phone number you want to locate.</p>
              </div>
              <div className="how-card">
                <h6>Request Location</h6>
                <p>Enter the phone number you want to locate.</p>
              </div>
              <div className="how-card">
                <h6>Request Location</h6>
                <p>Enter the phone number you want to locate.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-con">
                <div className="how-img">
                  <video width="700" height="520" controls className="video">
                    <source src="/locaradarWorking.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                {/* <div className="youtube">
                  <img src="images-local/youtube.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FEATURES */}

      <div className="container-fluid features">
        <div className="ellipse"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="feature-left">
                <h1>
                  Some of Our best <span>Features</span>
                </h1>
                <p>
                  Risus commodo id odio turpis pharetra elementum. Pulvinar
                  porta porta feugiat scelerisque in elit. Morbi rhoncus,
                  tellus, eros consequat magna semper orci a tincidunt.{" "}
                </p>
                <div>
                  <button>SHOW MORE</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-card">
                <div>
                  <img src="images-local/location.png" alt="" />
                </div>
                <span>Features</span>
                <h2>Location By Link</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                {/* <div>
                  <img src="images-local/location.png" alt="" />
                </div> */}
                <span>Features</span>
                <h2>Email & Phone Leak checker</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                {/* <div>
                  <img src="images-local/location.png" alt="" />
                </div> */}
                <span>Features</span>
                <h2>Get location with easy steps</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-card">
                {/* <div>
                  <img src="images-local/location.png" alt="" />
                </div> */}
                <span>Features</span>
                <h2>Phone Number Basic details</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                <div>
                  <img src="images-local/location.png" alt="" />
                </div>
                <span>Features</span>
                <h2>Send Location request</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                {/* <div>
                  <img src="images-local/location.png" alt="" />
                </div> */}
                <span>Features</span>
                <h2>Phone Number Basic details</h2>
                <h6>
                  Read More <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WANT TO KNOW */}

      <div className="container-fluid know">
        <div className="col-lg-6 mx-auto">
          <h1 className="col-lg-10 mx-auto">
            Want to know the
            <span> Location ?</span>
          </h1>
          <p>
            Locaradar is a trustworthy phone tracker by number that can find
            individuals no matter where they are.
          </p>
          <div className="know-button">
            <Link to="/tanitim" onClick={handleClick}>
              <button>Locate Now</button>
            </Link>
          </div>
        </div>
      </div>

      {/* FAQ */}

      <div className="container-fluid faq">
        <div className="container">
          <h1>
            Frequently Asked <span>Questions</span>
          </h1>
          <div className="row faq-row">
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 1 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(1);
                    }}
                  >
                    {showFaq === 1 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 1 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 2 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(2);
                    }}
                  >
                    {showFaq === 2 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 2 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 3 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(3);
                    }}
                  >
                    {showFaq === 3 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 3 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 4 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(4);
                    }}
                  >
                    {showFaq === 4 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 4 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 5 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(5);
                    }}
                  >
                    {showFaq === 5 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 5 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>Can I track someone by cell phone number?</h4>
                  <span
                    className={`span ${showFaq === 6 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(6);
                    }}
                  >
                    {showFaq === 6 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 6 && (
                  <p>
                    Ut enim ad minim veniam quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat aute irure
                    dolor
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Tanitum;
